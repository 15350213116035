import * as ContainerContext from '@strava/container-context';
import * as PropTypes from 'prop-types';
import * as React from 'react';
import Cldr from '../../utils/Cldr';
import { devLog } from '../../utils/devUtils';
import I18n from '../../utils/I18n';
import {
  logError,
  logMessage,
  setTag,
  setTags,
  setContext,
  withScope
} from '../../utils/sentry';
import styles from './styles.scss';

// eslint-disable-next-line no-underscore-dangle
window.__mfe_containers__ ??= {};

const ENTRY_ERROR_CODE_GENERAL = 0;

const ENTRY_ERROR_CODE_NETWORK = 1;

const DEFAULT_ACTIVE_SHARE_SCOPE = {
  react: {
    [React.version]: {
      get: () => () => React,
      from: 'webpack4',
      loaded: true,
      singleton: true
    }
  },
  '@strava/container-context': {
    // eslint-disable-next-line no-undef
    [CONTAINER_CONTEXT_VERSION]: {
      get: () => () => ContainerContext,
      from: 'webpack4',
      loaded: true,
      singleton: true
    }
  }
};

const DEFAULT_ANALYTICS_CONTEXT_VALUE = {
  track: (data) => window.Strava.ExternalAnalytics.trackV2(data)
};

const DEFAULT_TRANSLATION_CONTEXT_VALUE = {
  t: (key, props = {}) => I18n.t(key, props),
  getLanguage: () => I18n.language(),
  Cldr
};

const DEFAULT_ERROR_LOGGING_CONTEXT_VALUE = {
  logError,
  logMessage,
  setContext,
  setTag,
  setTags,
  withScope
};

const {
  AnalyticsContext,
  ErrorLoggingContext,
  ExperimentContext,
  TranslationContext
} = ContainerContext;

const { Provider: AnalyticsProvider } = AnalyticsContext;
const { Provider: TranslationProvider } = TranslationContext;
const { Provider: ErrorLoggingProvider } = ErrorLoggingContext;
const { Provider: ExperimentProvider } = ExperimentContext;

const isNil = (value) => value === null || value === undefined;

const isFunction = (value) => typeof value === 'function';

const hasProperty = (object, property) =>
  Object.prototype.hasOwnProperty.call(object, property);

const resolveRemoteContainer = ({
  shareScope = {},
  remoteEntry,
  containerName
}) => {
  // eslint-disable-next-line no-underscore-dangle
  const containers = window.__mfe_containers__;

  containers[containerName] ??= new Promise((resolve, reject) => {
    const headEl = document.querySelector('head');
    const scriptEl = document.createElement('script');

    const onEntryError = (error, code = ENTRY_ERROR_CODE_GENERAL) => {
      if (error instanceof Error) {
        reject(error);
      } else {
        const entryError = new Error(error);

        entryError.name = 'MfeError';
        entryError.code = code;

        reject(entryError);
      }
    };

    const onEntryLoaded = () => {
      if (!hasProperty(window, containerName)) {
        onEntryError(
          `Remote container with name "${containerName}" does not exist.`
        );
        return;
      }

      const container = window[containerName];

      if (isNil(container)) {
        onEntryError(`Remote container with name "${containerName}" is nil.`);
        return;
      }

      if (!isFunction(container.get) || !isFunction(container.init)) {
        onEntryError(
          `Remote container with name "${containerName}" is invalid.`
        );
        return;
      }

      Promise.resolve(container.init(shareScope))
        .catch(onEntryError)
        .then(() => {
          resolve(container);
          devLog(`MFE Loaded:\n${containerName}\n${remoteEntry}`);
        });
    };

    scriptEl.src = remoteEntry;
    scriptEl.type = 'text/javascript';
    scriptEl.async = true;

    scriptEl.addEventListener('load', () => onEntryLoaded());
    scriptEl.addEventListener('error', () =>
      onEntryError(
        `Remote container with name "${containerName}" failed to load.`,
        ENTRY_ERROR_CODE_NETWORK
      )
    );

    headEl.appendChild(scriptEl);
  });

  return containers[containerName];
};

const createFederatedComponent = (options) => {
  const {
    mfeStatus,
    shareScope,
    remoteEntry,
    containerName,
    containerRequest
  } = options;

  return React.lazy(() => {
    const onBeforeUnload = () => {
      window.removeEventListener('beforeunload', onBeforeUnload);
      mfeStatus.isUnloading = true;
    };

    const cleanup = () => {
      window.removeEventListener('beforeunload', onBeforeUnload);
    };

    const tapReject = (error) => {
      cleanup();
      return Promise.reject(error);
    };

    mfeStatus.isResolving = true;

    window.addEventListener('beforeunload', onBeforeUnload);

    return resolveRemoteContainer({
      shareScope,
      remoteEntry,
      containerName
    })
      .catch(tapReject)
      .then((container) => {
        const moduleFactory = Promise.resolve(container.get(containerRequest))
          .catch(tapReject)
          .then((value) => {
            cleanup();

            mfeStatus.isResolving = false;

            return Promise.resolve(value);
          });

        return moduleFactory.then((resolveModule) => resolveModule());
      });
  });
};

class MfeErrorBoundary extends React.Component {
  static contextType = ErrorLoggingContext;

  state = { error: false };

  static getDerivedStateFromError = (error) => ({ error });

  componentDidCatch(error, { componentStack }) {
    if (this.isFalsePositiveNetworkError(error)) {
      return;
    }

    const { shareScope } = this.props;

    const { logError: logException = logError } =
      /** @type {{ logError?: typeof logError }} */ (this.context ?? {});

    logException(error, {
      contexts: {
        contexts: {
          react: { componentStack },
          microfrontend: {
            // eslint-disable-next-line no-underscore-dangle
            containers: Object.keys(window.__mfe_containers__),
            shareScope: JSON.stringify(
              Object.keys(shareScope).reduce(
                (memo, current) => ({
                  ...memo,
                  [current]: Object.keys(shareScope[current])
                }),
                {}
              ),
              null,
              2
            )
          }
        }
      }
    });
  }

  isFalsePositiveNetworkError(error) {
    const { mfeStatus } = this.props;
    const { isUnloading, isResolving } = mfeStatus;

    const isChunkLoadError = error.name === 'ChunkLoadError';
    const isMfeNetworkError =
      error.name === 'MfeError' && error.code === ENTRY_ERROR_CODE_NETWORK;

    return (
      (isUnloading || isResolving) && (isChunkLoadError || isMfeNetworkError)
    );
  }

  render() {
    const { error } = this.state;
    const { children } = this.props;

    if (error) {
      return null;
    }

    return children;
  }
}

MfeErrorBoundary.propTypes = {
  children: PropTypes.element.isRequired,
  mfeStatus: PropTypes.shape({
    isUnloading: PropTypes.bool.isRequired,
    isResolving: PropTypes.bool.isRequired
  }).isRequired,
  shareScope: PropTypes.shape({}).isRequired
};

const Microfrontend = ({
  url: remoteEntry,
  scope: containerName,
  component: containerRequest,
  appContext,
  experiments
}) => {
  const isMountedRef = React.useRef(false);
  const mfeStatusRef = React.useRef({ isUnloading: false, isResolving: false });
  const shareScope = DEFAULT_ACTIVE_SHARE_SCOPE;
  const analyticsContext =
    React.useContext(AnalyticsContext) ?? DEFAULT_ANALYTICS_CONTEXT_VALUE;
  const translationContext =
    React.useContext(TranslationContext) ?? DEFAULT_TRANSLATION_CONTEXT_VALUE;
  const errorLoggingContext =
    React.useContext(ErrorLoggingContext) ??
    DEFAULT_ERROR_LOGGING_CONTEXT_VALUE;

  const experimentContext =
    React.useContext(ExperimentContext).experiment ?? {
          getExperiment: (name) => experiments[name]
    };

  const wrappedErrorLoggingContext = React.useMemo(() => {
    const {
      logError: originalLogError,
      logMessage: originalLogMessage
    } = errorLoggingContext;

    /** @type {typeof logError} */
    const wrappedLogError = (exception, captureContext) => {
      withScope((scope) => {
        scope.setTags({
          'mfe.name': containerName,
          'mfe.request': containerRequest,
          'mfe.remoteEntry': remoteEntry
        });

        originalLogError(exception, captureContext);
      });
    };

    /** @type {typeof logMessage} */
    const wrappedLogMessage = (exception, captureContext) => {
      withScope((scope) => {
        scope.setTags({
          'mfe.name': containerName,
          'mfe.request': containerRequest,
          'mfe.remoteEntry': remoteEntry
        });

        originalLogMessage(exception, captureContext);
      });
    };

    return {
      ...errorLoggingContext,
      logError: wrappedLogError,
      logMessage: wrappedLogMessage,
      setContext,
      setTag,
      setTags,
      withScope
    };
  }, [containerName, containerRequest, remoteEntry, errorLoggingContext]);

  const [
    { errorBoundaryKey, FederatedComponent },
    updateMfeState
  ] = React.useState(() => ({
    errorBoundaryKey: 0,
    FederatedComponent: createFederatedComponent({
      mfeStatus: mfeStatusRef.current,
      shareScope,
      remoteEntry,
      containerName,
      containerRequest
    })
  }));

  React.useEffect(() => {
    if (!isMountedRef.current) {
      return;
    }

    updateMfeState(({ errorBoundaryKey: currentBoundaryKey }) => ({
      errorBoundaryKey: currentBoundaryKey + 1,
      FederatedComponent: createFederatedComponent({
        mfeStatus: mfeStatusRef.current,
        shareScope,
        remoteEntry,
        containerName,
        containerRequest
      })
    }));
  }, [
    shareScope,
    remoteEntry,
    isMountedRef,
    mfeStatusRef,
    containerName,
    containerRequest
  ]);

  React.useEffect(() => {
    isMountedRef.current = true;

    return () => {
      isMountedRef.current = false;
    };
  }, [isMountedRef]);

  return (
    <div className={styles.mfcontainer}>
      <MfeErrorBoundary
        key={errorBoundaryKey}
        mfeStatus={mfeStatusRef.current}
        shareScope={shareScope}
        containerName={containerName}
        containerRequest={containerRequest}
      >
        <AnalyticsProvider value={analyticsContext}>
          <TranslationProvider value={translationContext}>
            <ErrorLoggingProvider value={wrappedErrorLoggingContext}>
              <ExperimentProvider value={experimentContext}>
                <React.Suspense fallback={null}>
                  <FederatedComponent appContext={appContext} />
                </React.Suspense>
              </ExperimentProvider>
            </ErrorLoggingProvider>
          </TranslationProvider>
        </AnalyticsProvider>
      </MfeErrorBoundary>
    </div>
  );
};

Microfrontend.propTypes = {
  url: PropTypes.string.isRequired,
  scope: PropTypes.string.isRequired,
  component: PropTypes.string.isRequired,
  appContext: PropTypes.shape({}).isRequired,
  experiments: PropTypes.shape({}).isRequired
};

export default Microfrontend;
