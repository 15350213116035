export default {
  calendar: {
    weekdays(...args) {
      const calendar = window.TwitterCldr.Calendar;
      return calendar.weekdays.call(calendar, ...args);
    }
  },
  formatCurrency(amount, currency) {
    const currencyFormatter = new window.TwitterCldr.CurrencyFormatter();
    return currencyFormatter.format(amount, { currency });
  },
  formatDateTime(date, options) {
    const dateTimeFormatter = new window.TwitterCldr.DateTimeFormatter();
    return dateTimeFormatter.format(date, options);
  },
  formatDecimal(value, precision) {
    const decimalFormatter = new window.TwitterCldr.DecimalFormatter();
    return decimalFormatter.format(value, { precision });
  },
  formatTimespan(time, options) {
    const timespanFormatter = new window.TwitterCldr.TimespanFormatter();
    return timespanFormatter.format(
      time - Math.round(Date.now() / 1000),
      options
    );
  }
};
